<template>
  <form @submit.prevent="onSaveVehicle" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input v-model="fleet.vehicle_name" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Name..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Type</label>

      <select class="bge-input bge-select rounded" v-model="fleet.vehicle_type">
        <option value="Car">Car</option>
        <option value="Van">Van</option>
        <option value="Bus">Bus</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Identification Number</label>
      <input
        v-model="fleet.vehicle_identification_number"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Identification Number..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Registration Date</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="fleet.registration_date"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Vehicle Category</label>
      <select class="bge-input bge-select rounded" v-model="fleet.vehicle_category">
        <option value="Hatchback">Hatchback</option>
        <option value="Sedan">Sedan</option>
        <option value="Minivan">Minivan</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Make</label>
      <select class="bge-input bge-select rounded" v-model="fleet.make">
        <option value="Aston Martin ">Aston Martin </option>
        <option value="Lotus ">Lotus </option>
        <option value="Mini">Mini</option>
        <option value="Honda">Honda</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Chassis No.</label>
      <input v-model="fleet.chassis_no" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Chassis No...." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Colour</label>
      <input v-model="fleet.colour" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Colour..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Manufacturer</label>
      <select class="bge-input bge-select rounded" v-model="fleet.manufacturer">
        <option value="Aston Martin ">Aston Martin </option>
        <option value="Lotus ">Lotus </option>
        <option value="Mini">Mini</option>
        <option value="Honda">Honda</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Manufacture Year</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="fleet.manufacture_year"
        minimum-view="year"
        format="yyyy"
      ></datepicker>
      <!-- <input
        v-model="fleet.manufacture_year"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Manufacture Year..."
      /> -->
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Engine Size</label>
      <input v-model="fleet.engine_size" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Engine Size..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Seat Capacity</label>
      <select class="bge-input bge-select rounded" v-model="fleet.seat_capacity">
        <option value="Aston Martin ">1</option>
        <option value="2 ">2</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="10+">10+</option>
        <option value="15+">15+</option>
        <option value="20+">20+</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Fuel Type</label>
      <select class="bge-input bge-select rounded" v-model="fleet.fuel_type">
        <option value="Petrol">Petrol</option>
        <option value="Diesel ">Diesel</option>
        <option value="LPG">LPG</option>
        <option value="Electric">Electric</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">License Plate</label>
      <input v-model="fleet.license_plate" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="License Plate..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Tax Start</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="fleet.tax_start"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Tax Run</label>
      <input v-model="fleet.tax_run" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Tax Run..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Tax Class</label>
      <select class="bge-input bge-select rounded" v-model="fleet.tax_class">
        <option value="Class-1">Class-1</option>
        <option value="Class-2">Class-2</option>
        <option value="Class-3">Class-3</option>
        <option value="Class-4">Class-4</option>
        <option value="Class-5">Class-5</option>
        <option value="Class-6">Class-6</option>
        <option value="Class-7">Class-7</option>
      </select>
    </div>

    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="onSaveVehicle"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Vehicle</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditVehicle",
  components: {
    Datepicker,
    IsAuthorized,
  },
  props: {
    fleet: {
      required: true,
      type: Object,
    },
  },
  methods: {
    onSaveVehicle: function() {
      this.$emit("complete", this.fleet);
    },
  },
};
</script>
